import React, { useEffect } from "react"

export default function Background(props) {
  return (
    <div
      onClick={() => {
        props.onClick && props.onClick()
      }}
      className="bg-image row"
    >
      <div className={props.container ? "container-fluid" : "m-auto"}>
        {props.children}
      </div>
    </div>
  )
}
