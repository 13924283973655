import React from "react"
import useSound from "use-sound"
import clickSfx from "../../static/sounds/click.mp3"

export default function Button(props) {
  let style = {
    fontSize: "4em",
    padding: "0.5em",
    width: "10em",
  }

  const [playClick] = useSound(clickSfx)

  if (props.size && props.size === "medium") {
    style = {
      fontSize: "2em",
      padding: "0.5em",
      width: "6em",
    }
  }

  if (props.size && props.size === "small") {
    style = {
      fontSize: "1em",
      padding: "0.5em",
      width: "6em",
    }
  }

  return (
    <button
      type="button"
      disabled={props.disabled ? props.disabled : false}
      className={`btn btn-${props.type ? props.type : "primary"} mr-4`}
      style={style}
      onClick={() => {
        playClick()
        props.onClick && props.onClick()
      }}
    >
      {props.text}
    </button>
  )
}
