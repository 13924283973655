import React from "react"
import { navigate } from "gatsby"
import Button from "../components/Button"
import Background from "../components/Background"
import iosQR from "../../static/images/ios-primarius.png"
import googleQR from "../../static/images/google-primarius.png"

export default function Finish() {
  return (
    <Background>
      <div className="d-flex flex-column align-items-center">
        <h1 className="display-3 font-weight-bold ml-4 mt-4">Thank you!</h1>

        <h1 className="display-5 font-weight-bold mt-4 p-4">
          For the last step, kindly download our app
          <br />
          to start looking for jobs!
        </h1>

        <div className="d-flex justify-content-xl-around">
          <div style={{ marginRight: 100 }}>
            <h3 className="text-center">iOS</h3>
            <img src={iosQR} alt="Primarius on Apple App Store" />
          </div>
          <div style={{ marginLeft: 100 }}>
            <h3 className="text-center">Android</h3>
            <img src={googleQR} alt="Primarius on Google Play Store" />
          </div>
        </div>

        <div className="p-4 d-flex">
          <Button text="Finish" size="medium" onClick={() => navigate("/")} />
        </div>
      </div>
    </Background>
  )
}
